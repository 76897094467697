import * as AllIcons from "@heroicons/react/outline";
import { useState } from "react";
import { IProject } from "../../../model/model";
import { menuItems } from "../Icon/ThreeDotsDropdownMenu";
import {
  IProjectGroupWithProjects,
  fetchAllCurrentElements,
  getProjectGroupPermissionsFromServer,
  setCurrentProjectGroupPermissions,
  setSelectedProjectGroup,
  setSelectedProjectGroupFromId,
} from "../../../features/projectGroups/state/projectGroupsSlice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../common/state/hooks";
import {
  getProjectPermissionsFromServer,
  getProjectTemplates,
  projectsSelector,
  setCurrentProject,
  setCurrentProjectPermissions,
  setEdited,
} from "../../../features/projects/state/projectsSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ProjectsInProjectGroup from "./ProjectsInProjectGroup";
import DisplayThreeDotsDropdownMenu from "./DisplayThreeDotsDropdownMenu";
import { PROJECT_GROUP_LIST_COMPONENT_ID } from "src/features/projectGroups/components/ProjectGroupList";
import ChevronRightIconButton from "./ChevronRightIconButton";
import { getIsAdminOfOrganization } from "src/features/profile/state/profileSlice";
import { PGThumbnail } from "../PGThumbnail/PGThumbnail";

interface ButtonSquareIconProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  projectGroupWithProjects: IProjectGroupWithProjects;
  handleThreeDotButtonClick: ({
    item,
    itemId,
    action,
  }: {
    item: string;
    itemId: number;
    action: string;
  }) => void;
  simplified?: boolean;
  openProjectGroup: (projectGroup: any) => void;
  setEditedProjectGroupId?: (id: number, projectGroup: any) => void;
}

const ButtonSquareIconsProjectGroupCreator = ({
  projectGroupWithProjects,
  handleThreeDotButtonClick,
  simplified,
  openProjectGroup,
  setEditedProjectGroupId,
  ...props
}: ButtonSquareIconProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isAdmin = useSelector(getIsAdminOfOrganization);

  const pg = {
    s3Url: projectGroupWithProjects.source_thumbnail.s3_url,
    name: projectGroupWithProjects.name,
    id: projectGroupWithProjects.id,
    elements: [...projectGroupWithProjects.project_group_elements]
      .filter((pge) => pge.project?.is_deleted !== true)
      .sort((a, b) => a.order - b.order),
    authType: projectGroupWithProjects.auth_type,
  };

  const disabledDelete = projectGroupWithProjects.has_existing_build || !isAdmin;

  const menuItems: menuItems = [
    {
      menuItemText: t("general.modify"),
      itemId: t("general.modify"),
      icon: "PencilAltIcon",
    },
    {
      menuItemText: t("general.delete"),
      itemId: t("general.delete"),
      icon: "TrashIcon",
      disabled: disabledDelete,
      disabledTooltipText: !disabledDelete // Don't display tooltip if the button isn't disabled
        ? ""
        : projectGroupWithProjects.has_existing_build
        ? t("tooltip.thisCourseHasBeenPublished")
        : t("tooltip.mustBeAdminToDeleteCourse"),
    },
    // { menuItemText: "Favourite", icon: "StarIcon" },
    // { menuItemText: "Fait template", icon: "SaveAsIcon" },
  ];
  let starColor = "stroke-white";

  const [toggleStar, setToggleStar] = useState(false);

  if (toggleStar) {
    starColor = "fill-yellow-500 stroke-yellow-500";
  }

  const getAllProjects = useSelector(projectsSelector);

  const handleClick = ({
    project,
    projectGroup,
  }: {
    project: IProject;
    projectGroup: IProjectGroupWithProjects;
  }) => {
    dispatch(setSelectedProjectGroupFromId(Number(pg.id)));
    dispatch(
      fetchAllCurrentElements({
        projectGroupId: Number(pg.id),
        componentId: PROJECT_GROUP_LIST_COMPONENT_ID,
      }),
    );

    dispatch(setCurrentProject(project));
    dispatch(setEdited());
    navigate(`/project-groups/${projectGroup.id}/list/${project.id}/graph`);
    dispatch(setSelectedProjectGroup(projectGroup));
    dispatch(getProjectPermissionsFromServer({ projectId: Number(project.id) })).then((res) => {
      dispatch(setCurrentProjectPermissions((res.payload as any).data));
    });
    dispatch(getProjectGroupPermissionsFromServer({ projectGroupId: Number(pg.id) })).then(
      (res) => {
        dispatch(setCurrentProjectGroupPermissions((res.payload as any).data));
      },
    );
  };

  const createNewProject = ({ projectGroup }: { projectGroup: IProjectGroupWithProjects }) => {
    dispatch(getProjectTemplates({ componentId: "projectTemplateComponent" }));
    dispatch(setSelectedProjectGroupFromId(Number(pg.id)));
    dispatch(
      fetchAllCurrentElements({
        projectGroupId: Number(projectGroup.id),
        componentId: PROJECT_GROUP_LIST_COMPONENT_ID,
      }),
    );
    dispatch(setSelectedProjectGroup(projectGroup));
    dispatch(
      getProjectGroupPermissionsFromServer({ projectGroupId: Number(projectGroup.id) }),
    ).then((res) => {
      dispatch(setCurrentProjectGroupPermissions((res.payload as any).data));
    });
    navigate(`/project-groups/${projectGroup.id}/new`);
  };

  const pgElementsLength = pg.elements.length > 0;

  const MetaDetails = () => {
    const allProjectsLength = pgElementsLength
      ? `${pg.elements.length} module${pg.elements.length > 1 ? "s" : ""}`
      : "0 modules";

    let prettyAuthType = t("pages.accessTypeTable.passwordLogin");
    if (pg.authType === "simplified") {
      prettyAuthType = t("pages.accessTypeTable.simplifiedLogin");
    }
    if (pg.authType === "none") {
      prettyAuthType = t("pages.accessTypeTable.noLogin");
    }

    return <>{`${prettyAuthType} - ${allProjectsLength}`}</>;
  };

  return (
    <div
      className={`bg-[#e6e6e6] relative rounded-3xl nyanya ${
        simplified ? "" : "w-fit min-w-[50.6rem] "
      } font-medium h-[14rem] bg-center bg-cover bg-gray-300 bg-opacity-50 bg-blend-soft-light shadow-md cursor-pointer`}
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        openProjectGroup(pg);
      }}
    >
      <button {...props}>
        <div className="pt-2 ml-4 flex grow h-full justify-between ">
          {/* the "Favorites" feature is disabled for now */}
          <div
            className="justify-start hidden" // removed flex because eslint was complaining it was causing a warning with hidden which has the same effect
            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
              event.stopPropagation();
            }}
          >
            <AllIcons.StarIcon
              onClick={() => setToggleStar(!toggleStar)}
              className={`w-6 h-6 ${starColor}`}
            />
          </div>
          <PGThumbnail src={pg.s3Url} title={pg.name} />

          <div
            className={` text-green-500 text-2xl  flex justify-evenly ${
              pgElementsLength ? " text-left  flex-col mt-0" : "-mt-5"
            } ml-2`}
          >
            <div className="flex items-center">
              <p className={`${pg.name.length > 20 ? "text-xl md:text-base" : "text-3xl"} `}>
                {pg.name}
              </p>
              <div className="relative w-6 h-6 ml-3">
                <ChevronRightIconButton className="w-6 h-6" />
              </div>
            </div>
            <p
              className={`text-sm text-gray-600 font-semibold ${
                pgElementsLength ? "" : "mt-8 ml-3"
              } `}
            >
              <MetaDetails />
            </p>
          </div>
        </div>
      </button>

      <DisplayThreeDotsDropdownMenu
        // currentProjectGroupElement={currentProjectGroupElement}
        isEnduser={false}
        menuItems={menuItems}
        pg={pg}
        handleThreeDotButtonClick={handleThreeDotButtonClick}
        setEditedProjectGroupId={setEditedProjectGroupId}
      />
      <ProjectsInProjectGroup
        handleClick={handleClick}
        handleThreeDotButtonClick={handleThreeDotButtonClick}
        numberOfRelevantProjectElements={pg.elements.length}
        projectGroupWithProjects={projectGroupWithProjects}
        allProjectsEver={getAllProjects}
        simplified={simplified}
        createNewProject={createNewProject}
      />
    </div>
  );
};

export default ButtonSquareIconsProjectGroupCreator;
