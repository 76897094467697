import React from "react";
import Chatbot from "src/features/chatbot/components/Chatbot";

const ScenarioHelperChat: React.FC = () => {
  return (
    <div className="h-full">
      <Chatbot mode="scenarioHelper" />
    </div>
  );
};

export default ScenarioHelperChat;
