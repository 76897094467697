import apiUrls from "src/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IAppLoaderAction } from "src/common/state/loaderHandleMiddleware";
import { UserFormResults } from "../components/chatState";

export const createChat = createAsyncThunk(
  "gpt/createChat",
  async (
    {
      userPrompt,
      userFormResults,
      cb, // Add callback for UI updates
    }: {
      userPrompt: string;
      userFormResults: UserFormResults;
      cb?: (chunk: string) => void; // Optional callback to handle streaming
    },
    { rejectWithValue },
  ) => {
    const jwt = localStorage.getItem("wixar.auth.jwt");
    try {
      const response = await fetch(apiUrls.gpt.createChat, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({
          data: {
            userPrompt,
            userFormResults,
          },
        }),
      });

      if (!response.body) {
        throw new Error("No response body");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let done = false;
      let botMessage = "";

      // Stream the response chunks
      while (!done) {
        const { value, done: readerDone } = await reader.read();
        done = readerDone;

        if (value) {
          const chunk = decoder.decode(value);
          botMessage += chunk;

          // Call the callback with the current chunk to update the UI
          if (cb) {
            cb(chunk);
          }
        }
      }

      return botMessage; // Return the final bot response once streaming completes
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue("API call failed");
    }
  },
);

export const updateChat = createAsyncThunk(
  "gpt/updateChat",
  async (
    {
      userPrompt,
      cb,
    }: {
      userPrompt: string;
      cb?: (chunk: string) => void;
    },
    { rejectWithValue },
  ) => {
    const jwt = localStorage.getItem("wixar.auth.jwt");
    try {
      const response = await fetch(apiUrls.gpt.updateChat, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({ userPrompt }),
      });

      if (!response.body) {
        throw new Error("No response body");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let done = false;
      let botMessage = "";

      while (!done) {
        const { value, done: readerDone } = await reader.read();
        done = readerDone;

        if (value) {
          const chunk = decoder.decode(value);
          botMessage += chunk;

          // Call the callback to update UI with the chunk
          if (cb) {
            cb(chunk);
          }
        }
      }

      return botMessage;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue("API call failed");
    }
  },
);

export const resetChat = createAsyncThunk(
  "gpt/resetChat",
  // eslint-disable-next-line no-empty-pattern
  async ({ cb }: { cb(): any } & IAppLoaderAction) => {
    const jwt = localStorage.getItem("wixar.auth.jwt");
    return await fetch(apiUrls.gpt.resetChat, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
    })
      .then(() => {
        cb();
        return null;
      })
      .catch((e) => {
        console.error("resetChat did an oopsie: ", e);
        return e;
      });
  },
);

export const askWixarpedia = createAsyncThunk(
  "gpt/askWixarpedia",
  async (
    {
      userPrompt,
      cb,
    }: {
      userPrompt: string;
      cb?: (chunk: string) => void; // Optional callback to handle streaming
    },
    { rejectWithValue },
  ) => {
    const jwt = localStorage.getItem("wixar.auth.jwt");
    try {
      const response = await fetch(apiUrls.gpt.askWixarpedia, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({
          data: {
            userPrompt,
          },
        }),
      });

      if (!response.body) {
        throw new Error("No response body");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let done = false;
      let botMessage = "";

      // Stream the response chunks
      while (!done) {
        const { value, done: readerDone } = await reader.read();
        done = readerDone;

        if (value) {
          const chunk = decoder.decode(value);
          botMessage += chunk;

          // Call the callback with the current chunk to update the UI
          if (cb) {
            cb(chunk);
          }
        }
      }

      return botMessage; // Return the final bot response once streaming completes
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue("API call failed");
    }
  },
);
