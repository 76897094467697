import { HomeIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { RootState } from "src/common/state/store";
import { getSelectedProjectGroup } from "../../../features/projectGroups/state/projectGroupsSlice";
import { getSelectedProjectId } from "../../../features/stats/state/statsSlice";
import { NavigationItem } from "./NavigationItem";

interface NavigationProps {
  titles: string;
}

const Breadcrumb = ({ titles }: NavigationProps) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const [pathnames, setPathnames] = useState<string[]>([]);
  const [pathRoute, setPathRoute] = useState<string[]>([]);

  // using the selected project group and project id to display the breadcrumb
  const selectedProjectGroup = useSelector(getSelectedProjectGroup);
  const selectedProjectId = useSelector(getSelectedProjectId);

  // Getting deleted project group from the "stats slice"
  const projectGroups = useSelector((state: RootState) => state.stats.allSessionsByProjectGroup);
  const selectedProjectGroupId = useSelector(
    (state: RootState) => state.stats.selectedProjectGroupId,
  );

  const getDeletedProjectGroupName = () => {
    const projectGroup = projectGroups.find(
      (group) => group.project_group_id === selectedProjectGroupId,
    );

    return projectGroup ? projectGroup.project_group_name : "project-groups";
  };

  //For calculate the lengt paths
  const pathArray = pathname.split("/").filter(Boolean);
  const lengthPath = pathArray.length;

  //const for not display the Project groups in double

  useEffect(() => {
    if (pathArray[0] === "templates") {
      setPathRoute(["templates"]);
      setPathnames(["templates"]);
    } else if (pathArray[0] === "project-groups") {
      const pathArrayWithProjectGroupId = pathArray.slice(0, 2);
      setPathnames(pathArrayWithProjectGroupId);
      setPathRoute(pathArrayWithProjectGroupId);
    } else if (lengthPath === 3) {
      // limit breadcrumb length to 2
      const pathArrayWithProjectGroupId = pathArray.slice(0, 2);
      setPathnames(pathArrayWithProjectGroupId);
      setPathRoute(pathArrayWithProjectGroupId);
    } else if (pathArray[0] === "medias") {
      setPathnames(["medias"]);
      setPathRoute(["medias"]);
    } else if (pathArray[0] === "statistics") {
      const newArrayPaths = pathArray.slice(0, 3);
      if (selectedProjectGroup?.name) {
        newArrayPaths[1] = selectedProjectGroup?.name;
      } else {
        newArrayPaths[1] = getDeletedProjectGroupName();
      }

      if (selectedProjectId) {
        newArrayPaths.push(`${selectedProjectId}`); // add the selectedProjectId to the breadcrumb
      }
      setPathRoute(pathArray.slice(0, 3));
      setPathnames(newArrayPaths);
    } else if (pathArray[0] === "chat") {
      if (lengthPath >= 2) {
        if (pathArray[1] === "scenario-helper") {
          setPathRoute(["chat", "scenario-helper"]);
          setPathnames(["chat", "scenario-helper"]);
        }
        if (pathArray[1] === "wixarpedia") {
          setPathRoute(["chat", "wixarpedia"]);
          setPathnames(["chat", "wixarpedia"]);
        }
      }
    } else {
      setPathnames(pathArray);
    }
  }, [selectedProjectGroup, selectedProjectId, pathname]);

  return (
    <nav className="flex mb-3" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-3">
        {pathnames.length ? (
          <li className="inline-flex items-center">
            <Link
              to={"/"}
              className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white "
            >
              <HomeIcon className="mr-2 w-4 h-4" />
              {t("general.home")}
            </Link>
          </li>
        ) : (
          <HomeIcon className="mr-2 w-4 h-4 inline-flex items-center text-sm font-medium text-gray-700" />
        )}

        {pathnames
          .filter((p) => p)
          .map((name, index) => {
            return (
              <NavigationItem
                key={`${index}_`}
                name={name}
                index={index}
                titles={titles}
                pathRoute={pathRoute}
                pathnames={pathnames}
                pathArray={pathArray}
                pathname={pathname}
                selectedProjectGroup={selectedProjectGroup}
              />
            );
          })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
