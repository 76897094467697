import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const AIChatButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate("/chat/scenario-helper")}
      className="relative inline-block cursor-pointer text-yellow-400 font-semibold text-lg whitespace-nowrap transition-all duration-300 mb-1 ml-2"
    >
      <span className="relative z-10 text-yellow-400 hover:text-yellow-500 animate-glow-pulse">
        {`Wixar ${t("general.ai")}✨`}
      </span>
    </div>
  );
};

export default AIChatButton;
