const parseMarkdown = (markdown: string) => {
  const lines = markdown.split("\n");
  const scenes: { title: string; media: string; interactions: string[] }[] = [];

  let currentScene: any = null;

  lines.forEach((line) => {
    if (line.startsWith("### ")) {
      if (currentScene) {
        scenes.push(currentScene);
      }
      currentScene = { title: line.replace("### ", ""), media: "", interactions: [] };
    } else if (line.startsWith("#### Média:") && currentScene) {
      currentScene.media = line.replace("#### Média: ", "");
    } else if (line.startsWith("- **") && currentScene) {
      // Extract text between ** **
      const match = line.match(/\*\*(.*?)\*\*/);
      if (match) {
        const interaction = match[1].trim();
        currentScene.interactions.push(interaction);
      }
    }
  });

  if (currentScene) {
    scenes.push(currentScene);
  }

  return scenes;
};

export default parseMarkdown;
